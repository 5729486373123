import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddPaymentGatewayValidations } from "../../data/validations";
import { AddPaymentDetails, GetSingleOrganization } from "../../controller/organization";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function PaymentGateway({id}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;

  useEffect(() => {
    if (id) {
      setLoader(true);
      GetSingleOrganization(token, id)
        .then((res) => {
          console.log(res?.result, "response");
          setData(res?.result);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const AddPaymentGatewayDetails = (values, { resetForm }) => {
    AddPaymentDetails(id, values, token)
      .then((res) => {
        toast.success(res?.message);
        resetForm({});
        navigate("/superadmin/org-management");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="mt-4  px-4">
        {loader ? (
          <p>Loading.....</p>
        ) : (
          <Formik
            initialValues={{
              gateway_name: data?.gateway_name ? data?.gateway_name : "",
              gateway_location: data?.gateway_location
                ? data?.gateway_location
                : "",
              email: data?.email ? data?.email : "",
              gateway_contact: data?.gateway_contact
                ? data?.gateway_contact
                : "",
              gateway_auth_person: data?.gateway_auth_person
                ? data?.gateway_auth_person
                : "",
              gateway_licence_no: data?.gateway_licence_no
                ? data?.gateway_licence_no
                : "",
            }}
            onSubmit={AddPaymentGatewayDetails}
            validationSchema={AddPaymentGatewayValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="border border-[#dfdfdf] rounded-xl p-4">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Payment Gateway Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Name"
                            type="text"
                            name="gateway_name"
                            onChange={handleChange("gateway_name")}
                            onBlur={handleBlur("gateway_name")}
                            value={values.gateway_name}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.gateway_name && touched.gateway_name ? (
                            <p className="text-[#ef4444]">
                              {errors.gateway_name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Payment Gateway Location
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Location"
                            type="typeOfBranch"
                            name="gateway_location"
                            onChange={handleChange("gateway_location")}
                            onBlur={handleBlur("gateway_location")}
                            value={values.gateway_location}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.gateway_location &&
                          touched.gateway_location ? (
                            <p className="text-[#ef4444]">
                              {errors.gateway_location}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224]">
                            Payment Gateway Email
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Email"
                            name="email"
                            type="email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-[#ef4444]">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224]">
                            Payment Gateway Contact
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Contact"
                            name="gateway_contact"
                            onChange={handleChange("gateway_contact")}
                            onBlur={handleBlur("gateway_contact")}
                            value={values.gateway_contact}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.gateway_contact && touched.gateway_contact ? (
                            <p className="text-[#ef4444]">
                              {errors.gateway_contact}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Payment Gateway Auth Person
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Auth Person"
                            type="text"
                            name="gateway_auth_person"
                            onChange={handleChange("gateway_auth_person")}
                            onBlur={handleBlur("gateway_auth_person")}
                            value={values.gateway_auth_person}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.gateway_auth_person &&
                            touched.gateway_auth_person ? (
                              <p className="text-[#ef4444]">
                                {errors.gateway_auth_person}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224]">
                            Payment Gateway Licence No.
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Licence No."
                            name="gateway_licence_no"
                            onChange={handleChange("gateway_licence_no")}
                            onBlur={handleBlur("gateway_licence_no")}
                            value={values.gateway_licence_no}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.gateway_licence_no &&
                            touched.gateway_licence_no ? (
                              <p className="text-[#ef4444]">
                                {errors.gateway_licence_no}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-end gap-4 mt-8">
                    <button
                      onClick={handleSubmit}
                      className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/* new field */}
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default PaymentGateway;
