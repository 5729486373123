import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { AddNewCompanyValidations } from "../../data/validations";
import {
  AddNewComplaintApi,
  GetPincodeApi,
  ViewSingleAdminComplaintsApi,
} from "../../controller/complaints";
import { IoEyeOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineUploadFile } from "react-icons/md";
import moment from "moment";
import ReactCustomSelect from "../../components/ui/ReactCustomSelect";
import { GetAllBanks } from "../../controller/organization";
import DashboardHeader from "../../components/DashboardHeader";
import RecieverBankComp from "../../components/RecieverBankComp";
import SwitchBankComp from "../../components/SwitchBankComp";
import PaymentGateway from "../../components/PaymentGateway";
import Merchant from "../../components/Merchant";
import Modal from "../../components/Modal";
import { base } from "../../config";

function AddNewComplaint() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isAadharModalOpen, setIsAadharModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPanModalOpen, setIsPanModalOpen] = useState(false);
  const [city, setCity] = useState();
  const [pincode, setPincode] = useState();
  const [viewComplaint, setViewComplaint] = useState({});
  const [formState, setFormState] = useState({
    reciever_Bank: false,
    switch_Bank: false,
    payment_gateway: false
  });
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const token = JSON.parse(authToken);
  const personalBankDetails = token.user;
  const [allBanks, setAllBanks] = useState();
  const fileInputRef = useRef(null);
  const fileAadharInputRef = useRef(null);
  const [userBankName, setUserBankName] = useState(null);
  useEffect(() => {
    setFormState({
      ...formState,
      reciever_Bank: viewComplaint?.user_bank_status?.receiver_bank_name
        ? true
        : false,
      switch_Bank:
        viewComplaint?.user_bank_status?.receiver_bank_name &&
        viewComplaint?.receiver_bank_status?.switch_bank
          ? true
          : false,
      payment_gateway:
      viewComplaint?.receiver_bank_status?.switch_bank &&
        viewComplaint?.switch_bank_status?.payment_gateway
          ? true
          : false,
      merchant:  viewComplaint?.payment_gateway_status?.merchant &&
      viewComplaint?.receiver_bank_status?.switch_bank
        ? true
        : false,
    });
  }, [viewComplaint]);

  const bank = allBanks?.find(
    (item) =>
      item?.bankname === viewComplaint?.user_bank_status?.receiver_bank_name
  );

  useEffect(() => {
    if (allBanks && personalBankDetails) {
      const foundBank = allBanks.find(
        (item) => item?.bankname === personalBankDetails?.bank_name
      );
      setUserBankName(foundBank); // Update state
      // console.log("userBankName in useEffect----", foundBank);
    }
  }, [allBanks, personalBankDetails]);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const customStyles = (hasError) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: state.isFocused ? 0 : 0,
    }),
  });

  const handleAadharIconClick = () => {
    if (fileAadharInputRef.current) {
      fileAadharInputRef.current.click();
    }
  };

  useEffect(() => {
    if (location?.state) {
      setLoading(true);
      ViewSingleAdminComplaintsApi(location?.state, token?.token)
        .then((res) => {
          // console.log("ress----", res.data);
          setViewComplaint(res?.data);

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, []);

  // pincode api start

  const getAddressDetails = (pincode, setFieldValue) => {
    // console.log("pincode-------->>",pincode)
    if (pincode.length === 6) {
      // console.log("Valid 6-digit pincode: ", pincode);
      GetPincodeApi(pincode)
        .then((res) => {
          setPincode(res[0]?.PostOffice);
          setCity(res[0]?.PostOffice);
          setFieldValue("country", res[0]?.PostOffice[0]?.Country);
          setFieldValue("city", res[0]?.PostOffice[0]?.Name);
          setFieldValue("state", res[0]?.PostOffice[0]?.State);
          setFieldValue("district", res[0]?.PostOffice[0]?.District);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Invalid pincode length, skipping API call.");
    }
  };

  // useEffect(() => {
  //   GetPincodeApi()
  //     .then((res) => {
  //       setPincode(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // pincode api end

  useEffect(() => {
    GetAllBanks(token.token)
      .then((res) => {
        setAllBanks(res.bank);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const callApi = (value) => {
    // console.log("00000===>>>", value);

    let data = new FormData();
    // Object.keys(value).map((key) => {
    //   data.append(key, value[key]); // Append key-value pairs to FormData
    // });
    // console.log("data-",data);

    data.append("aadhar_card_no", value?.aadhar_card_no);
    data.append("aadhar_img", value?.aadhar_img);
    data.append("address", value?.address);
    data.append("city", value?.city);
    data.append("complaint_category", value?.complaint_category);
    data.append("complaint_sub_category", value?.complaint_sub_category);
    data.append("country", value?.country);
    data.append("district", value?.district);
    data.append("email", value?.email);
    data.append("first_name", value?.first_name);
    data.append("last_name", value?.last_name);
    data.append("mobile_no", value?.mobile_no);
    data.append("pan_card_no", value?.pan_card_no);
    data.append("pan_img", value?.pan_img);
    data.append("payee_vpa", value?.payee_vpa);
    data.append("payer_vpa", value?.payer_vpa);
    data.append("pincode", value?.pincode);
    data.append("receiver_bank_IFSC", value?.receiver_bank_IFSC);
    data.append("receiver_bank_account_no", value?.receiver_bank_account_no);
    data.append("receiver_bank_name", value?.receiver_bank_name);
    data.append("receiver_name", value?.receiver_name);
    data.append("refund_reason", value?.refund_reason);
    data.append("refund_status", value?.refund_status);
    data.append("attachment", value?.attachment);
    data.append("state", value?.state);
    data.append("transaction_amount", value?.transaction_amount);
    data.append("transaction_date_time", value?.transaction_date_time);
    data.append("transaction_failed_reason", value?.transaction_failed_reason);
    data.append("transaction_id", value?.transaction_id);
    data.append("transaction_remark", value?.transaction_remark);
    data.append("transaction_screenshot", value?.transaction_screenshot);
    data.append("transaction_status", value?.transaction_status);
    data.append("transaction_type", value?.transaction_type);
    data.append("txn_verification_status", value?.txn_verification_status);
    data.append("user_bank_account_no", value?.user_bank_account_no);
    data.append("user_bank_ifsc_code", value?.user_bank_ifsc_code);
    data.append("user_bank_name", value?.user_bank_name);
    data.append("bank_ref_no", value?.bank_ref_no);
    data.append("user_transation_status", value?.user_transation_status);
    data.append("utr_no", value?.utr_no);
    data.append("verification_remark", value?.verification_remark);
    // data.append("receiver_bank_IFSC", value?.receiver_bank_IFSC);

    {
      value?.switch_bank && data.append("switch_bank", value?.switch_bank);
    }

    {
      value?.switch_bank &&
        data.append("complaint_number", value?.complaint_number);
    }

    AddNewComplaintApi(token.token, location?.state, data)
      // setIsSubmitting(true)
      .then((res) => {
        toast.success(res.message);
        setIsSubmitting(false);
        // resetForm({});
        navigate("/admin/complaint-management");
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };
  const handleADD = (values) => {
    console.log("value0--0-0---0-0-0-0-0-", values);
    setIsSubmitting(true);
    callApi(values);
  };

  return (
    <div>
      <div className="bg-[#FAFBFF] overflow-x-hidden">
        <div>
          <DashboardHeader
            title={"Pages / Complaint Management"}
            subtitle={"Complaint Management"}
          />
        </div>

        {loading ? (
          <p>Loading....</p>
        ) : (
          <div>
            <Formik
              initialValues={{
                complaint_category: viewComplaint
                  ? viewComplaint?.consumer_Details?.complaint_category
                  : "",
                complaint_sub_category: viewComplaint
                  ? viewComplaint?.consumer_Details?.complaint_sub_category
                  : "",
                first_name: viewComplaint
                  ? viewComplaint?.consumer_Details?.first_name
                  : "",
                last_name: viewComplaint
                  ? viewComplaint?.consumer_Details?.last_name
                  : "",
                mobile_no: viewComplaint
                  ? viewComplaint?.consumer_Details?.mobile_no
                  : "",
                email: viewComplaint
                  ? viewComplaint?.consumer_Details?.email
                  : "",
                address: viewComplaint
                  ? viewComplaint?.consumer_Details?.address
                  : "",
                city: viewComplaint
                  ? viewComplaint?.consumer_Details?.city
                  : "",
                pincode: viewComplaint
                  ? viewComplaint?.consumer_Details?.pincode
                  : "",
                district: viewComplaint
                  ? viewComplaint?.consumer_Details?.district
                  : "",
                state: viewComplaint
                  ? viewComplaint?.consumer_Details?.state
                  : "",
                country: viewComplaint
                  ? viewComplaint?.consumer_Details?.country
                  : "",
                aadhar_card_no: viewComplaint
                  ? viewComplaint?.consumer_Details?.aadhar_card_no
                  : "",
                pan_card_no: viewComplaint
                  ? viewComplaint?.consumer_Details?.pan_card_no
                  : "",
                pan_img: "",
                aadhar_img: "",
                transaction_type: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_type
                  : "",
                transaction_date_time: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_date_time
                  : "",
                transaction_amount: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_amount
                  : "",
                transaction_id: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_id
                  : "",
                utr_no: viewComplaint
                  ? viewComplaint?.transaction_Details?.utr_no
                  : "",
                transaction_status: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_status
                  : "",
                // user_bank_name: viewComplaint
                //   ? viewComplaint?.transaction_Details?.user_bank_name
                //   : "",
                user_bank_name: viewComplaint?.transaction_Details
                  ?.user_bank_name
                  ? viewComplaint?.transaction_Details?.user_bank_name
                  : "",
                user_bank_account_no: viewComplaint
                  ? viewComplaint?.transaction_Details?.user_bank_account_no
                  : "",
                bank_ref_no: viewComplaint
                  ? viewComplaint?.user_bank_status?.bank_ref_no
                  : "",
                // user_bank_ifsc_code: viewComplaint
                //   ? viewComplaint?.transaction_Details?.user_bank_ifsc_code
                //   : "",
                user_bank_ifsc_code: viewComplaint?.transaction_Details
                  ?.user_bank_ifsc_code
                  ? viewComplaint?.transaction_Details?.user_bank_ifsc_code
                  : "",
                receiver_name: viewComplaint
                  ? viewComplaint?.transaction_Details?.receiver_name
                  : "",
                payee_vpa: viewComplaint
                  ? viewComplaint?.transaction_Details?.payee_vpa
                  : "",
                payer_vpa: viewComplaint
                  ? viewComplaint?.transaction_Details?.payer_vpa
                  : "",
                transaction_screenshot: viewComplaint
                  ? viewComplaint?.transaction_Details?.transaction_screenshot
                  : "",
                txn_verification_status: viewComplaint
                  ? viewComplaint?.user_bank_status?.txn_verification_status
                  : "",
                verification_remark: viewComplaint
                  ? viewComplaint?.user_bank_status?.verification_remark
                  : "",
                user_transation_status: viewComplaint
                  ? viewComplaint?.user_bank_status?.user_transation_status
                  : "",
                refund_status: viewComplaint
                  ? viewComplaint?.user_bank_status?.refund_status
                  : "",
                transaction_failed_reason: viewComplaint
                  ? viewComplaint?.user_bank_status?.transaction_failed_reason
                  : "",
                refund_reason: viewComplaint
                  ? viewComplaint?.user_bank_status?.refund_reason
                  : "",
                receiver_bank_account_no: viewComplaint
                  ? viewComplaint?.user_bank_status?.receiver_bank_account_no
                  : "",
                receiver_bank_IFSC: viewComplaint
                  ? viewComplaint?.user_bank_status?.receiver_bank_IFSC
                  : "",
                transaction_remark: viewComplaint
                  ? viewComplaint?.user_bank_status?.transaction_remark
                  : "",
                receiver_bank_name: viewComplaint
                  ? viewComplaint?.user_bank_status?.receiver_bank_name
                  : "",
                attachment: viewComplaint
                  ? viewComplaint?.user_bank_status?.attachment
                  : "",
              }}
              onSubmit={handleADD}
              validationSchema={AddNewCompanyValidations}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <div className="border border-[#dfdfdf] rounded-xl p-4">
                  <div className="flex flex-wrap items-center gap-2 my-2">
                    <div>
                      <h3 className="font-nunito font-semibold text-lg text-primary ">
                        Complaint Number :-
                      </h3>
                    </div>

                    <div>
                      {viewComplaint?.complaint_Details?.complaint_number}
                    </div>
                  </div>

                  <div>
                    <div className="mt-4">
                      <h3 className="text-primary font-poppins font-medium text-xl">
                        Complaint Detail
                      </h3>
                    </div>

                    {viewComplaint?.complaint_Details?.complaint_number && (
                      <div className="flex flex-wrap ">
                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div>
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Complaint Number
                              </h3>
                            </div>
                            <div className="mt-3">
                              <input
                                placeholder="Complaint Number"
                                onChange={handleChange("complaint_number")}
                                onBlur={handleBlur("complaint_number")}
                                value={
                                  viewComplaint?.complaint_Details
                                    ?.complaint_number
                                }
                                disabled
                                className="w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.complaint_number &&
                              touched.complaint_number ? (
                                <p className="text-[#ef4444]">
                                  {errors.complaint_number}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div>
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Complaint Date
                              </h3>
                            </div>
                            <div className="mt-3">
                              <input
                                placeholder="Compalint Date"
                                onChange={handleChange("complaint_date")}
                                onBlur={handleBlur("complaint_date")}
                                value={moment(viewComplaint?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                                disabled
                                className="w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              />
                              {errors.complaint_date &&
                              touched.complaint_date ? (
                                <p className="text-[#ef4444]">
                                  {errors.complaint_date}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-2">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Complaint Category
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444]  text-red-500">
                                *
                              </span>
                            </div>
                          </div>
                          <div className="pt-3">
                            <select
                              onChange={(e) =>
                                setFieldValue(
                                  "complaint_category",
                                  e.target.value
                                )
                              }
                              defaultValue={
                                viewComplaint?.complaint_Details
                                  ?.complaint_category
                              }
                              className={`${
                                errors.complaint_category
                                  ? "w-full bg-[#F7F7FA] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                  : "w-full bg-[#F7F7FA] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              }`}
                            >
                              <option defaultChecked className="text-lg">
                                --Select Category--
                              </option>
                              <option
                                value={"Online Financial Fraud"}
                                className="text-lg"
                              >
                                Online Financial Fraud
                              </option>
                            </select>
                          </div>

                          {errors?.complaint_category &&
                          touched?.complaint_category ? (
                            <p className="text-[#ef4444]">
                              {errors?.complaint_category}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="w-full md:w-1/2">
                        <div className="m-2">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Complaint Sub Category
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444]  text-red-500">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="pt-3">
                            <select
                              onChange={(e) =>
                                setFieldValue(
                                  "complaint_sub_category",
                                  e.target.value
                                )
                              }
                              defaultValue={
                                viewComplaint?.complaint_Details
                                  ?.complaint_sub_category
                              }
                              className={`${
                                errors.complaint_sub_category
                                  ? "w-full bg-[#F7F7FA] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                  : "w-full bg-[#F7F7FA] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                              }`}
                            >
                              <option defaultChecked className="text-lg">
                                --Select Sub Category--
                              </option>
                              <option
                                value={"Aadhar Enabled Payment System (AEPS)"}
                                className="text-lg"
                              >
                                Aadhar Enabled Payment System (AEPS)
                              </option>
                              <option
                                value={
                                  "Business Email Compromise/Email Takeover"
                                }
                                className="text-lg"
                              >
                                Business Email Compromise/Email Takeover
                              </option>
                              <option
                                value={"Debit/Credit Card Fraud/Sim Swap Fraud"}
                                className="text-lg"
                              >
                                Debit/Credit Card Fraud/Sim Swap Fraud
                              </option>
                              <option
                                value={"Demat/Depository Fraud"}
                                className="text-lg"
                              >
                                Demat/Depository Fraud
                              </option>
                              <option
                                value={"E-Wallet Related Fraud"}
                                className="text-lg"
                              >
                                E-Wallet Related Fraud
                              </option>
                              <option
                                value={"Fraud Call/Vishing"}
                                className="text-lg"
                              >
                                Fraud Call/Vishing
                              </option>
                              <option
                                value={"Internet Banking Related Fraud"}
                                className="text-lg"
                              >
                                Internet Banking Related Fraud
                              </option>
                              <option
                                value={"UPI Related Frauds"}
                                className="text-lg"
                              >
                                UPI Related Frauds
                              </option>
                            </select>
                          </div>
                          {errors?.complaint_sub_category &&
                          touched?.complaint_sub_category ? (
                            <p className="text-[#ef4444]">
                              {errors?.complaint_sub_category}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-2">
                    <div>
                      <h3 className="text-primary font-poppins font-medium text-xl">
                        User Detail
                      </h3>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            First Name
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="First Name"
                            onChange={handleChange("first_name")}
                            onBlur={handleBlur("first_name")}
                            value={values.first_name}
                            disabled={
                              viewComplaint?.consumer_Details?.first_name
                                ? true
                                : false
                            }
                            className={`${
                              errors.first_name
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.first_name && touched?.first_name ? (
                            <p className="text-[#ef4444]">
                              {errors?.first_name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Last Name
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Last Name"
                            onChange={handleChange("last_name")}
                            onBlur={handleBlur("last_name")}
                            value={values.last_name}
                            disabled={
                              viewComplaint?.consumer_Details?.last_name
                                ? true
                                : false
                            }
                            className={`${
                              errors.last_name
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.last_name && touched?.last_name ? (
                            <p className="text-[#ef4444]">
                              {errors?.last_name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Mobile No.
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Mobile No."
                            onChange={handleChange("mobile_no")}
                            onBlur={handleBlur("mobile_no")}
                            value={values.mobile_no}
                            disabled={
                              viewComplaint?.consumer_Details?.mobile_no
                                ? true
                                : false
                            }
                            className={`${
                              errors.mobile_no
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.mobile_no && touched?.mobile_no ? (
                            <p className="text-[#ef4444]">
                              {errors?.mobile_no}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Email Id.
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Email Id"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            disabled={
                              viewComplaint?.consumer_Details?.email
                                ? true
                                : false
                            }
                            className={`${
                              errors.email
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors.email && touched.email ? (
                            <p className="text-[#ef4444]">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="w-full ">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Address
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="address"
                            onChange={handleChange("address")}
                            onBlur={handleBlur("address")}
                            value={values.address}
                            disabled={
                              viewComplaint?.consumer_Details?.address
                                ? true
                                : false
                            }
                            className={`${
                              errors.address
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />

                          {errors?.address && touched?.address ? (
                            <p className="text-[#ef4444]">{errors?.address}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-full md:w-1/3">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Pincode
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        {/* <div className="mt-3">
                          <input
                            placeholder="Pincode"
                            onChange={handleChange("pincode")}
                            onBlur={handleBlur("pincode")}
                            value={values.pincode}
                            disabled={
                              viewComplaint?.consumer_Details?.pincode
                                ? true
                                : false
                            }
                            className={`${
                              errors.pincode
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.pincode && touched?.pincode ? (
                            <p className="text-[#ef4444]">{errors?.pincode}</p>
                          ) : null}
                        </div> */}

                        <div className="mt-3">
                          <input
                            placeholder="Pincode"
                            onChange={(e) => {
                              const pincode = e?.target?.value;
                              setFieldValue("pincode", pincode);
                              getAddressDetails(pincode, setFieldValue);
                            }}
                            onBlur={handleBlur("pincode")}
                            value={values.pincode}
                            disabled={
                              viewComplaint?.consumer_Details?.pincode
                                ? true
                                : false
                            }
                            className={`${
                              errors.pincode
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.pincode && touched?.pincode ? (
                            <p className="text-[#ef4444]">{errors?.pincode}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/3">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            City
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                           

                          <select
                            onChange={(e) =>
                              setFieldValue("city", e.target.value)
                            }
                            defaultValue={
                              viewComplaint?.consumer_Details
                                ?.city
                            }
                            className={`${
                              errors.city
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                            disabled={
                              viewComplaint?.consumer_Details?.city
                                ? true
                                : false
                            }
                   
                          >
                            <option className="text-lg">
                           { viewComplaint?.consumer_Details
                            ?.city ? viewComplaint?.consumer_Details
                            ?.city : " --Select City --" }
                            </option>
                            {city?.map((item, index) => (
                              <>
                                <option
                                  key={index}
                                  value={item?.Name}
                                  className="text-lg"
                                >
                                  {item?.Name + " " + item?.Block}
                                </option>
                              </>
                            ))}
                          </select>
                          {errors?.city && touched?.city ? (
                            <p className="text-[#ef4444]">{errors?.city}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/3">
                      <div className="m-3">
                        <div className="flex gap-1">
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            District
                          </h3>
                          <div>
                            <span className=" font-bold text-[#ef4444]  text-red-500">
                              *
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="District"
                            onChange={handleChange("district")}
                            onBlur={handleBlur("district")}
                            disabled={
                              viewComplaint?.consumer_Details?.district
                                ? true
                                : false
                            }
                            value={values.district}
                            className={`${
                              errors.district
                                ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                            }`}
                          />
                          {errors?.district && touched?.district ? (
                            <p className="text-[#ef4444]">{errors?.district}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/*  */}

                    {/* START */}
                    <div className="flex flex-wrap ">
                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224]">
                              State
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444]  text-red-500">
                                *
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-3/4">
                              <input
                                placeholder="State"
                                onChange={handleChange("state")}
                                onBlur={handleBlur("state")}
                                value={values.state}
                                disabled={
                                  viewComplaint?.consumer_Details?.state
                                    ? true
                                    : false
                                }
                                className={`${
                                  errors.state
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              />
                              {errors?.state && touched?.state ? (
                                <p className="text-[#ef4444]">
                                  {errors?.state}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Country
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444]  text-red-500">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="mt-3 flex items-center">
                            <div className="w-3/4">
                              <input
                                placeholder="Country"
                                onChange={handleChange("country")}
                                onBlur={handleBlur("country")}
                                disabled={
                                  viewComplaint?.consumer_Details?.country
                                    ? true
                                    : false
                                }
                                value={values.country}
                                className={`${
                                  errors.country
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              />
                              {errors?.country && touched?.country ? (
                                <p className="text-[#ef4444]">
                                  {errors?.country}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END */}

                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Aadhar Card No.
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444] text-red-500">
                                *
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-3/4">
                              <input
                                placeholder="Aadhar Card No."
                                onChange={handleChange("aadhar_card_no")}
                                onBlur={handleBlur("aadhar_card_no")}
                                disabled={
                                  viewComplaint?.consumer_Details
                                    ?.aadhar_card_no
                                    ? true
                                    : false
                                }
                                value={values?.aadhar_card_no}
                                className={`${
                                  errors?.aadhar_card_no
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              />
                              {errors?.aadhar_card_no &&
                              touched?.aadhar_card_no ? (
                                <p className="text-[#ef4444]">
                                  {errors?.aadhar_card_no}
                                </p>
                              ) : null}
                            </div>

                            <div className="w-1/4">
                              <div className="flex items-center">
                                {!viewComplaint?.consumer_Details
                                  ?.aadhar_img ? (
                                  <div
                                    className={
                                      errors.aadhar_img
                                        ? "w-8 border border-[#ef4444]"
                                        : "w-8 bg-[#E2ECFD]"
                                    }
                                  >
                                    <div className="flex justify-center p-2">
                                      <div>
                                        <input
                                          type="file"
                                          ref={fileAadharInputRef}
                                          className="hidden"
                                          onChange={(event) => {
                                            const file = event.target.files[0];
                                            if (file) {
                                              console.log(
                                                "Selected file:",
                                                file
                                              );
                                              setFieldValue("aadhar_img", file);
                                            }
                                          }}
                                        />
                                        <MdOutlineUploadFile
                                          size={20}
                                          className="text-primary cursor-pointer"
                                          onClick={handleAadharIconClick}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="w-8 bg-[#E2ECFD]">
                                      <div className="flex justify-center p-2">
                                        <button
                                          onClick={() =>
                                            setIsAadharModalOpen(true)
                                          }
                                          className="text-xl"
                                        >
                                          <IoEyeOutline size={20} />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <Modal
                            isOpen={isAadharModalOpen}
                            onClose={() => setIsAadharModalOpen(false)}
                            title="Aadhar Card View"
                          >
                            <img
                              src={
                                base.BASE_URL +
                                viewComplaint?.consumer_Details?.aadhar_img.split(
                                  "public/"
                                )[1]
                              }
                            />
                          </Modal>
                        </div>
                      </div>

                      <div className="w-full md:w-1/2">
                        <div className="m-3">
                          <div className="flex gap-1">
                            <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                              Pan Card No.
                            </h3>
                            <div>
                              <span className=" font-bold text-[#ef4444]  text-red-500">
                                *
                              </span>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="w-3/4">
                              <input
                                placeholder="Pan Card No."
                                onChange={handleChange("pan_card_no")}
                                onBlur={handleBlur("pan_card_no")}
                                disabled={
                                  viewComplaint?.consumer_Details?.pan_card_no
                                    ? true
                                    : false
                                }
                                value={values?.pan_card_no}
                                className={`${
                                  errors?.pan_card_no
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              />
                              {errors?.pan_card_no && touched?.pan_card_no ? (
                                <p className="text-[#ef4444]">
                                  {errors?.pan_card_no}
                                </p>
                              ) : null}
                            </div>

                            <div className="w-1/4">
                              <div
                                className={
                                  errors.pan_img
                                    ? "w-8 border border-[#ef4444]"
                                    : "w-8 bg-[#E2ECFD]"
                                }
                              >
                                {!viewComplaint?.consumer_Details?.pan_img ? (
                                  <div className="flex justify-center p-2">
                                    <div className="relative">
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        className="hidden"
                                        onChange={(event) => {
                                          const file = event.target.files[0];
                                          if (file) {
                                            console.log("Selected file:", file);
                                            setFieldValue("pan_img", file);
                                          }
                                        }}
                                      />
                                      <MdOutlineUploadFile
                                        size={20}
                                        className="text-primary cursor-pointer"
                                        onClick={handleIconClick}
                                      />
                                      {errors.pan_img && touched.pan_img && (
                                        <span className="absolute top-[-90%] font-bold text-[#ef4444] right-[-70%] text-red-500">
                                          *
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="w-8 bg-[#E2ECFD]">
                                      <div className="flex justify-center p-2">
                                        <button
                                          onClick={() =>
                                            setIsPanModalOpen(true)
                                          }
                                          className="text-xl"
                                        >
                                          <IoEyeOutline size={20} />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Modal
                        isOpen={isPanModalOpen}
                        onClose={() => setIsPanModalOpen(false)}
                        title="Pan Card View"
                      >
                        <img
                          src={
                            base.BASE_URL +
                            viewComplaint?.consumer_Details?.pan_img.split(
                              "public/"
                            )[1]
                          }
                        />
                      </Modal>
                    </div>
                    <div>
                      <div className="mt-4">
                        <h3 className="text-primary font-poppins font-medium text-xl">
                          Transaction Details
                        </h3>
                      </div>

                      <div className="flex flex-wrap ">
                        <div className="w-full lg:w-1/2">
                          <div className="p-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Type
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="pt-3">
                              <select
                                onChange={(e) =>
                                  setFieldValue(
                                    "transaction_type",
                                    e.target.value
                                  )
                                }
                                className={`${
                                  errors.transaction_type
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                                disabled={
                                  viewComplaint?.transaction_Details
                                    ?.transaction_type
                                    ? true
                                    : false
                                }
                                defaultValue={
                                  viewComplaint?.transaction_Details
                                    ?.transaction_type
                                }
                              >
                                <option className="text-lg">
                                  --Select Transaction Type --
                                </option>
                                <option value={"upi"} className="text-lg">
                                  UPI
                                </option>
                                <option value={"imps"} className="text-lg">
                                  IMPS
                                </option>
                                <option
                                  value={"debit-card"}
                                  className="text-lg"
                                >
                                  Debit Card
                                </option>
                                <option
                                  value={"credit-card"}
                                  className="text-lg"
                                >
                                  Credit Card
                                </option>
                                <option value={"wallet"} className="text-lg">
                                  Wallet
                                </option>
                                <option
                                  value={"net-banking"}
                                  className="text-lg"
                                >
                                  Net Banking
                                </option>
                              </select>

                              {errors?.transaction_type &&
                              touched?.transaction_type ? (
                                <p className="text-[#ef4444]">
                                  {errors?.transaction_type}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="w-full lg:w-1/2">
                          <div className="p-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Date & Time
                              </h3>
                              <div>
                                <span className="font-bold text-[#ef4444]">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="pt-3">
                              <input
                                type="datetime-local"
                                onChange={handleChange("transaction_date_time")}
                                onBlur={handleBlur("transaction_date_time")}
                                value={values.transaction_date_time}
                                disabled={
                                  viewComplaint?.transaction_Details
                                    ?.transaction_date_time
                                    ? true
                                    : false
                                }
                                className={`${
                                  errors.transaction_date_time
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              />
                              {errors?.transaction_date_time &&
                              touched?.transaction_date_time ? (
                                <p className="text-[#ef4444]">
                                  {errors?.transaction_date_time}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap ">
                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Amount
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Transaction Amount"
                                  onChange={handleChange("transaction_amount")}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.transaction_amount
                                      ? true
                                      : false
                                  }
                                  onBlur={handleBlur("transaction_amount")}
                                  value={values.transaction_amount}
                                  className={`${
                                    errors.transaction_amount
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.transaction_amount &&
                                touched?.transaction_amount ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.transaction_amount}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction ID
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Transaction ID"
                                  onChange={handleChange("transaction_id")}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.transaction_id
                                      ? true
                                      : false
                                  }
                                  onBlur={handleBlur("transaction_id")}
                                  value={values.transaction_id}
                                  className={`${
                                    errors.transaction_id
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.transaction_id &&
                                touched?.transaction_id ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.transaction_id}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="m-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                UTR / RRN No.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="UTR No."
                                  onChange={handleChange("utr_no")}
                                  disabled={
                                    viewComplaint?.transaction_Details?.utr_no
                                      ? true
                                      : false
                                  }
                                  onBlur={handleBlur("utr_no")}
                                  value={values.utr_no}
                                  className={`${
                                    errors.utr_no
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.utr_no && touched?.utr_no ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.utr_no}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {console.log("valoooo",values)} */}

                        <div className="w-full lg:w-1/2">
                          <div className="p-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Status
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="pt-3">
                              <select
                                onChange={(e) => {
                                  setFieldValue(
                                    "transaction_status",
                                    e.target.value
                                  );
                                  //  setIsTxnStatusVisible(e.target.value);
                                }}
                                className={`${
                                  errors.transaction_status
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                                disabled={
                                  viewComplaint?.transaction_Details
                                    ?.transaction_status
                                    ? true
                                    : false
                                }
                                defaultValue={
                                  viewComplaint?.transaction_Details
                                    ?.transaction_status
                                }
                              >
                                <option className="text-lg">
                                  --Select Transaction Status --
                                </option>
                                <option value={"pending"} className="text-lg">
                                  Pending
                                </option>
                                <option value={"success"} className="text-lg">
                                  Success
                                </option>
                                <option value={"reject"} className="text-lg">
                                  Reject
                                </option>
                              </select>
                              {errors?.transaction_status &&
                              touched?.transaction_status ? (
                                <p className="text-[#ef4444]">
                                  {errors?.transaction_status}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                User Bank Name
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>

                            {console.log("viewComplaint===>>>", userBankName)}
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                {!viewComplaint?.transaction_Details
                                  ?.user_bank_name ? (
                                  <ReactCustomSelect
                                    styles={customStyles(
                                      errors?.section?.message
                                    )}
                                    defaultValue={userBankName}
                                    options={allBanks}
                                    multiSelect={true}
                                    setFieldValue={setFieldValue}
                                    name="user_bank_name"
                                    onchange={(item) =>
                                      // console.log("first==>>", item)
                                      setFieldValue(
                                        "user_bank_name",
                                        item.bankname
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    placeholder="User Bank Name"
                                    onChange={handleChange("user_bank_name")}
                                    onBlur={handleBlur("user_bank_name")}
                                    value={
                                      viewComplaint?.transaction_Details
                                        ?.user_bank_name
                                    }
                                    disabled={
                                      viewComplaint?.transaction_Details
                                        ?.user_bank_name
                                        ? true
                                        : false
                                    }
                                    className={`${
                                      errors.user_bank_name
                                        ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                        : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                    }`}
                                  />
                                )}

                                {errors.user_bank_name &&
                                touched.user_bank_name ? (
                                  <p className="text-[#ef4444]">
                                    {errors.user_bank_name}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                User Bank Account No.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="User Bank Account No."
                                  onChange={handleChange(
                                    "user_bank_account_no"
                                  )}
                                  onBlur={handleBlur("user_bank_account_no")}
                                  value={values.user_bank_account_no}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.user_bank_account_no
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.user_bank_account_no
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors.user_bank_account_no &&
                                touched.user_bank_account_no ? (
                                  <p className="text-[#ef4444]">
                                    {errors.user_bank_account_no}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                User Bank IFSC Code.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="User Bank IFSC Code."
                                  onChange={handleChange("user_bank_ifsc_code")}
                                  onBlur={handleBlur("user_bank_ifsc_code")}
                                  value={values.user_bank_ifsc_code}
                                  className={`${
                                    errors.user_bank_ifsc_code
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors.user_bank_ifsc_code &&
                                touched.user_bank_ifsc_code ? (
                                  <p className="text-[#ef4444]">
                                    {errors.user_bank_ifsc_code}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Receiver Name.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Receiver Name"
                                  onChange={handleChange("receiver_name")}
                                  onBlur={handleBlur("receiver_name")}
                                  value={values.receiver_name}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.receiver_name
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.receiver_name
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors.receiver_name &&
                                touched.receiver_name ? (
                                  <p className="text-[#ef4444]">
                                    {errors.receiver_name}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="m-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Payee VPA
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Payee VPA"
                                  onChange={handleChange("payee_vpa")}
                                  onBlur={handleBlur("payee_vpa")}
                                  value={values.payee_vpa}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.payee_vpa
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.payee_vpa
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />

                                {errors.payee_vpa && touched.payee_vpa ? (
                                  <p className="text-[#ef4444]">
                                    {errors.payee_vpa}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Payer VPA
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Payer VPA"
                                  onChange={handleChange("payer_vpa")}
                                  onBlur={handleBlur("payer_vpa")}
                                  value={values.payer_vpa}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.payer_vpa
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.payer_vpa
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors.payer_vpa && touched.payer_vpa ? (
                                  <p className="text-[#ef4444]">
                                    {errors.payer_vpa}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-3">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Screenshot
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="mt-3 flex items-center">

                            {!viewComplaint?.transaction_Details?.transaction_screenshot ? (
                              <div className="w-full">
                                
                                <input
                                  placeholder="Transaction Screenshot"
                                  type="file"
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                      // console.log("Selected file:", file);
                                      setFieldValue(
                                        "transaction_screenshot",
                                        file
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur("transaction_screenshot")}
                                  // value={values?.transaction_screenshot}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.transaction_screenshot
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors?.transaction_screenshot
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.transaction_screenshot &&
                                touched?.transaction_screenshot ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.transaction_screenshot}
                                  </p>
                                ) : null}
                              </div>
                              ):(
                                <div className="w-32 ">
                                <img
                                src={
                                  base.BASE_URL +
                                  viewComplaint?.transaction_Details?.transaction_screenshot.split(
                                    "public/"
                                  )[1]
                                }
                                className=" w-full object-cover"
                              />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="mt-4">
                        <h3 className="text-primary font-poppins font-medium text-xl">
                          User Bank Status
                        </h3>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Txn Verification Status
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="pt-3">
                              <select
                                defaultValue={
                                  viewComplaint?.user_bank_status
                                    ?.txn_verification_status
                                }
                                onChange={(e) =>
                                  setFieldValue(
                                    "txn_verification_status",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  viewComplaint?.user_bank_status
                                    ?.txn_verification_status
                                    ? true
                                    : false
                                }
                                className={`${
                                  errors.txn_verification_status
                                    ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                    : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                }`}
                              >
                                <option defaultChecked className="text-lg">
                                  --Select Status--
                                </option>
                                <option value={"verified"} className="text-lg">
                                  Verified
                                </option>
                                <option value={"rejected"} className="text-lg">
                                  Rejected
                                </option>
                              </select>
                            </div>

                            {errors?.txn_verification_status &&
                            touched?.txn_verification_status ? (
                              <p className="text-[#ef4444]">
                                {errors?.txn_verification_status}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Verification Remark
                              </h3>
                              {/* <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div> */}
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Verification Remark"
                                  onChange={handleChange("verification_remark")}
                                  onBlur={handleBlur("verification_remark")}
                                  value={values.verification_remark}
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.transaction_remark
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.verification_remark
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />

                                {errors?.verification_remark &&
                                touched?.verification_remark ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.verification_remark}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Status
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <select
                                  defaultValue={
                                    viewComplaint?.user_bank_status
                                      ?.user_transation_status
                                  }
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.user_transation_status
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    setFieldValue(
                                      "user_transation_status",
                                      e.target.value
                                    )
                                  }
                                  className={`${
                                    errors.user_transation_status
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                >
                                  <option defaultChecked className="text-lg">
                                    --Select Status--
                                  </option>
                                  <option value={"success"} className="text-lg">
                                    Success
                                  </option>
                                  <option value={"pending"} className="text-lg">
                                    Pending
                                  </option>
                                  <option value={"failed"} className="text-lg">
                                    Failed
                                  </option>
                                </select>

                                {errors?.user_transation_status &&
                                touched?.user_transation_status ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.user_transation_status}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Refund Status
                              </h3>
                              <div>
                                <span className="font-bold text-[#ef4444] text-red-500">
                                  *
                                </span>
                              </div>
                            </div>

                            {console.log("user bank----", viewComplaint,)}
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <select
                                  // defaultValue={
                                  //   viewComplaint?.user_bank_status
                                  //     ?.refund_status
                                  // }
                                  defaultValue={
                                    viewComplaint?.user_bank_status
                                      ?.refund_status
                                  }
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.refund_status
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    setFieldValue(
                                      "refund_status",
                                      e.target.value
                                    )
                                  }
                                  className={`${
                                    errors.refund_status
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                >
                                  <option defaultChecked className="text-lg">
                                    --Select Status--
                                  </option>
                                  <option value={"yes"} className="text-lg">
                                    Yes
                                  </option>
                                  <option value={"no"} className="text-lg">
                                    No
                                  </option>
                                </select>
                                {errors?.refund_status &&
                                touched?.refund_status ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.refund_status}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap">
                        {values?.user_transation_status != "success" && (
                          <div className="w-full md:w-1/2">
                            <div className="m-2">
                              <div className="flex gap-1">
                                <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                  Transaction Failed / Pending Reason
                                </h3>
                                <div>
                                  <span className=" font-bold text-[#ef4444]  text-red-500">
                                    *
                                  </span>
                                </div>
                              </div>
                              <div className="mt-3 flex items-center">
                                <div className="w-full">
                                  <input
                                    placeholder="Transaction Failed Reason"
                                    onChange={handleChange(
                                      "transaction_failed_reason"
                                    )}
                                    disabled={
                                      viewComplaint?.user_bank_status
                                        ?.transaction_failed_reason
                                        ? true
                                        : false
                                    }
                                    onBlur={handleBlur(
                                      "transaction_failed_reason"
                                    )}
                                    value={values?.transaction_failed_reason}
                                    className={`${
                                      errors?.transaction_failed_reason
                                        ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                        : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                    }`}
                                  />
                                  {errors?.transaction_failed_reason &&
                                  touched?.transaction_failed_reason ? (
                                    <p className="text-[#ef4444]">
                                      {errors?.transaction_failed_reason}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Refund Reason
                              </h3>
                              {/* <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div> */}
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Refund Reason"
                                  onChange={handleChange("refund_reason")}
                                  onBlur={handleBlur("refund_reason")}
                                  value={values.refund_reason}
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.refund_reason
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.refund_reason
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Receiver Bank Name
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <ReactCustomSelect
                                  styles={customStyles(
                                    errors?.section?.message
                                  )}
                                  defaultValue={bank}
                                  options={allBanks}
                                  multiSelect={true}
                                  onchange={(item) =>
                                    setFieldValue(
                                      "receiver_bank_name",
                                      item.bankname
                                    )
                                  }
                                />
                                {errors.receiver_bank_name &&
                                touched.receiver_bank_name ? (
                                  <p className="text-[#ef4444]">
                                    {errors.receiver_bank_name}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Receiver Bank Account No.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Receiver Bank Account No"
                                  onChange={handleChange(
                                    "receiver_bank_account_no"
                                  )}
                                  onBlur={handleBlur(
                                    "receiver_bank_account_no"
                                  )}
                                  value={values.receiver_bank_account_no}
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.receiver_bank_account_no
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.receiver_bank_account_no
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.receiver_bank_account_no &&
                                touched?.receiver_bank_account_no ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.receiver_bank_account_no}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Receiver Bank IFSC Code
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Receiver Bank IFSC Code"
                                  onChange={handleChange("receiver_bank_IFSC")}
                                  onBlur={handleBlur("receiver_bank_IFSC")}
                                  value={values.receiver_bank_IFSC}
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.receiver_bank_IFSC
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.receiver_bank_IFSC
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.receiver_bank_IFSC &&
                                touched?.receiver_bank_IFSC ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.receiver_bank_IFSC}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Transaction Remark
                              </h3>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Transaction Remark"
                                  onChange={handleChange("transaction_remark")}
                                  onBlur={handleBlur("transaction_remark")}
                                  value={values.transaction_remark}
                                  disabled={
                                    viewComplaint?.user_bank_status
                                      ?.transaction_remark
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.transaction_remark
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        < div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Attachment
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                            {!viewComplaint?.user_bank_status?.attachment ? (
                              <div className="w-full">
                                <input
                                  placeholder="Attachment"
                                  type="file"
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                      // console.log("Selected file:", file);
                                      setFieldValue("attachment", file);
                                    }
                                  }}
                                  onBlur={handleBlur("attachment")}
                                  // value={values?.attachment}
                                  disabled={
                                    viewComplaint?.transaction_Details
                                      ?.attachment
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors?.attachment
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />

                                {errors?.attachment && touched?.attachment ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.attachment}
                                  </p>
                                ) : null}
                              </div>
                            ):(
                              <div className="w-32 ">
                                <img
                                src={
                                  base.BASE_URL +
                                  viewComplaint?.user_bank_status?.attachment.split(
                                    "public/"
                                  )[1]
                                }
                                className=" w-full object-cover"
                              />
                              </div>

                            )}
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="m-2">
                            <div className="flex gap-1">
                              <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                                Bank Refernce Number.
                              </h3>
                              <div>
                                <span className=" font-bold text-[#ef4444]  text-red-500">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="mt-3 flex items-center">
                              <div className="w-full">
                                <input
                                  placeholder="Bank Reference Number"
                                  onChange={handleChange("bank_ref_no")}
                                  onBlur={handleBlur("bank_ref_no")}
                                  value={values.bank_ref_no}
                                  disabled={
                                    viewComplaint?.user_bank_status?.bank_ref_no
                                      ? true
                                      : false
                                  }
                                  className={`${
                                    errors.bank_ref_no
                                      ? "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                                      : "w-full  h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                                  }`}
                                />
                                {errors?.bank_ref_no && touched?.bank_ref_no ? (
                                  <p className="text-[#ef4444]">
                                    {errors?.bank_ref_no}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {console.log("viewComplaint-===", viewComplaint)}
                  {console.log("personalBankDetails==", personalBankDetails)}

                  {(viewComplaint?.user_bank_status?.receiver_bank_name ===
                    personalBankDetails.bank_name || viewComplaint?.receiver_bank_status) && (
                    <RecieverBankComp
                      // setFormState={setFormState}
                      viewComplaint={viewComplaint}
                      recieverBankDetails={viewComplaint?.receiver_bank_status}
                      id={location.state}
                      handleSubmit={callApi}
                      formState={formState}
                    />
                  )}

                  {/* switch bank status start */}
                  
                  {console.log("===view>>>",viewComplaint)}

                  {(viewComplaint?.receiver_bank_status?.switch_bank ===
                    personalBankDetails.bank_name || viewComplaint?.switch_bank_status) && (
                    <>
                      {loading ? (
                        <div> 
                          <p>Loading.......</p>
                        </div>
                      ) : (
                        viewComplaint?.receiver_bank_status?.switch_bank && (
                          <SwitchBankComp
                            id={location.state}
                            viewComplaint={viewComplaint}
                            receiver_bank_details={
                              viewComplaint?.receiver_bank_status
                            }
                            
                            switchBankDetails={
                              viewComplaint?.switch_bank_status
                            }
                            formState={formState}
                          />
                        )
                      )}
                    </>
                  )}


                  {(viewComplaint?.switch_bank_status?.payment_gateway ===
                    personalBankDetails.gateway_name || viewComplaint?.payment_gateway_status) && (
                    <>
                      {loading ? (
                        <div>
                          <p>Loading.......</p>
                        </div>
                      ) : (
                        viewComplaint?.switch_bank_status?.payment_gateway && (
                          <PaymentGateway
                            id={location.state}
                            payment_gateway_status={
                              viewComplaint?.payment_gateway_status
                            }
                            viewComplaint={viewComplaint}
                            formState={formState}
                          />
                        )
                      )}
                    </>
                  )}

                  {/* Merchant Form Start */}
                  {(viewComplaint?.switch_bank_status?.payment_gateway ===
                    personalBankDetails?.gateway_name) && (
                    <>
                      {loading ? (
                        <div>
                          <p>Loading.......</p>
                        </div>
                      ) : (
                        viewComplaint?.payment_gateway_status?.merchant && (
                          <Merchant
                            id={location.state}
                            merchant_status={viewComplaint?.merchant_status}
                            payment_gateway_status={
                              viewComplaint?.payment_gateway_status
                            }
                            formState={formState}
                          />
                        )
                      )}
                    </>
                  )}

                  {/* Merchant Form End */}

                  {/* switch bank status end */}

                  {!viewComplaint?.user_bank_status?.receiver_bank_name && (
                    <div className="flex items-center  justify-end gap-4 mt-8">
                      {isSubmitting ? (
                        <button className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]">
                          Submitting....
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  )}
                  {/* new field */}
                </div>
              )}
            </Formik>
          </div>
        )}
      </div>
      {/* <RecieverBankComp /> */}
    </div>
  );
}

export default AddNewComplaint;
