import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddMerchantValidations } from "../../data/validations";
import {
  AddMerchantDetails,
  GetSingleOrganization,
} from "../../controller/organization";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Merchant({ id }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const token = authToken.token;
  // console.log("sss",authToken)

  useEffect(() => {
    if (id) {
      setLoader(true);
      GetSingleOrganization(token, id)
        .then((res) => {
          setData(res?.result);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const AddMerchant = (values, { resetForm }) => {
    AddMerchantDetails(id,values, token)
      .then((res) => {
        toast.success(res.message);
        resetForm({});
        navigate("/superadmin/org-management");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="mt-4  px-4">
        {loader ? (
          <p>Loading.....</p>
        ) : (
          <Formik
            initialValues={{
              merchant_name: data?.merchant_name ? data?.merchant_name : "",
              email: data?.email ? data?.email : "",
              mid: data?.mid ? data?.mid : "",
              marchant_vpa: data?.marchant_vpa ? data?.marchant_vpa : "",
              marchant_address: data?.marchant_address
                ? data?.marchant_address
                : "",
              city: data?.city ? data?.city : "",
              state: data?.state ? data?.state : "",
              pincode: data?.pincode ? data?.pincode : "",
              country: data?.country ? data?.country : "",
              url_website: data?.url_website ? data?.url_website : "",
              app_url: data?.app_url ? data?.app_url : "",
              gateway_name: data?.gateway_name ? data?.gateway_name : "",
            }}
            onSubmit={AddMerchant}
            validationSchema={AddMerchantValidations}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <div className="border border-[#dfdfdf] rounded-xl p-4">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Merchant Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Merchant Name"
                            type="bank"
                            name="merchant_name"
                            onChange={handleChange("merchant_name")}
                            onBlur={handleBlur("merchant_name")}
                            value={values.merchant_name}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors?.merchant_name && touched?.merchant_name ? (
                            <p className="text-[#ef4444]">
                              {errors?.merchant_name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Merchant Email
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Merchant Email"
                            type="text"
                            name="email"
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            value={values.email}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-[#ef4444]">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap ">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Merchant VPA
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Merchant VPA"
                            name="marchant_vpa"
                            onChange={handleChange("marchant_vpa")}
                            onBlur={handleBlur("marchant_vpa")}
                            value={values.marchant_vpa}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.marchant_vpa && touched.marchant_vpa ? (
                            <p className="text-[#ef4444]">
                              {errors.marchant_vpa}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Merchant Address
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Merchant Address"
                            name="marchant_address"
                            onChange={handleChange("marchant_address")}
                            onBlur={handleBlur("marchant_address")}
                            value={values.marchant_address}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.marchant_address &&
                          touched.marchant_address ? (
                            <p className="text-[#ef4444]">
                              {errors.marchant_address}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            City
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="City"
                            name="city"
                            onChange={handleChange("city")}
                            onBlur={handleBlur("city")}
                            value={values.city}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.city && touched.city ? (
                              <p className="text-[#ef4444]">{errors.city}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            State
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="State"
                            name="state"
                            onChange={handleChange("state")}
                            onBlur={handleBlur("state")}
                            value={values.state}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.state && touched.state ? (
                              <p className="text-[#ef4444]">{errors.state}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Pincode
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Pincode"
                            name="pincode"
                            onChange={handleChange("pincode")}
                            onBlur={handleBlur("pincode")}
                            value={values.pincode}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.pincode && touched.pincode ? (
                              <p className="text-[#ef4444]">{errors.pincode}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Country
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Country"
                            name="country"
                            onChange={handleChange("country")}
                            onBlur={handleBlur("country")}
                            value={values.country}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.country && touched.country ? (
                              <p className="text-[#ef4444]">{errors.country}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            URL Website
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="URL Website"
                            name="url_website"
                            onChange={handleChange("url_website")}
                            onBlur={handleBlur("url_website")}
                            value={values.url_website}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.url_website && touched.url_website ? (
                              <p className="text-[#ef4444]">
                                {errors.url_website}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            App URL
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="App URL"
                            name="app_url"
                            onChange={handleChange("app_url")}
                            onBlur={handleBlur("app_url")}
                            value={values.app_url}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          <div>
                            {errors.app_url && touched.app_url ? (
                              <p className="text-[#ef4444]">{errors.app_url}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            MID
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="MID"
                            type="text"
                            name="mid"
                            onChange={handleChange("mid")}
                            onBlur={handleBlur("mid")}
                            value={values.mid}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />
                          {errors.mid && touched.mid ? (
                            <p className="text-[#ef4444]">{errors.mid}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className="m-3">
                        <div>
                          <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                            Payment Gateway Name
                          </h3>
                        </div>
                        <div className="mt-3">
                          <input
                            placeholder="Payment Gateway Name"
                            name="gateway_name"
                            onChange={handleChange("gateway_name")}
                            onBlur={handleBlur("gateway_name")}
                            value={values.gateway_name}
                            className="w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                          />

                          <div>
                            {errors.gateway_name && touched.gateway_name ? (
                              <p className="text-[#ef4444]">
                                {errors.gateway_name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-end gap-4 mt-8">
                    <button
                      onClick={handleSubmit}
                      className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                {/* new field */}
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default Merchant;
