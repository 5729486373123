import * as Yup from "yup";

export const AddNewCompanyValidations = Yup.object().shape({
  complaint_category: Yup.string().required("Complaint Category is required"),
  complaint_sub_category: Yup.string().required(
    "Complaint Sub Category is required"
  ),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  mobile_no: Yup.string()
    .required("Mobile No. is required")
    .min(10, "Mobile No. must be exactly 10 digits")
    .max(10, "Mobile No. must be exactly 10 digits"),
  email: Yup.string().email().required("Email Id  is required"),
  address: Yup.string().required("Address  is required"),
  city: Yup.string().required("City is required"),
  pincode: Yup.string().required("Pincode is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  // aadhar_card_no: Yup.string().required("Aadhar Card no. is required"),
  aadhar_card_no: Yup.string()
    .required("Aadhar Card no. is required")
    .length(12, "Aadhar Card no. must be exactly 12 digits")
    .matches(/^\d{12}$/, "Aadhar Card no. must contain only digits"),
  // pan_card_no: Yup.string().required("Pan Card no. is required"),
  pan_card_no: Yup.string()
    .required("Pan Card no. is required")
    .length(10, "Pan Card no. must be exactly 10 characters")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Pan Card no. must be in valid format (e.g., ABCDE1234F)"
    ),
  aadhar_img: Yup.string().required("Pan Card Image is required"),
  pan_img: Yup.string().required("Pan Card Image is required"),
  transaction_type: Yup.string().required("Transaction Type is required"),
  transaction_status: Yup.string().required("Transaction Status is required"),
  transaction_date_time: Yup.string().required("Transaction Date is required"),
  transaction_amount: Yup.string().required("Transaction Amount is required").matches(/^[0-9]+$/, "Transaction Amount must contain only numbers"),
  transaction_id: Yup.string().required("Transaction Id is required"),
  user_bank_name: Yup.string().required("User Bank Name is required"),
  // user_bank_account_no: Yup.string().required(
  //   "User Bank Account No. is required"
  // ),
  user_bank_account_no: Yup.string()
  .required("User Bank Account No. is required")
  .matches(/^[0-9]+$/, "User Bank Account No. must contain only numbers"),
  // user_bank_ifsc_code: Yup.string().required("User Bank IFSC Code is required"),
  user_bank_ifsc_code: Yup.string()
    .required("IFSC Code is required")
    .min(11, "IFSC Code must be 11 characters long")
    .max(11, "IFSC Code must be 11 characters long")
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format"),
  receiver_name: Yup.string().required("Receiver name  is required"),
  txn_verification_status: Yup.string().required(
    "Transaction Verification Status is required"
  ),
  // verification_remark: Yup.string().required("Verification Remark is required"),
  utr_no: Yup.string()
    .required("UTR no. is required")
    .length(12, "UTR no. must be exactly 12 digits")
    .matches(/^\d{12}$/, "UTR no. must contain only digits"),
  transaction_status: Yup.string().required("Transaction Status is required"),
  transaction_screenshot: Yup.string().required(
    "Transaction Screen Shot is required"
  ),
  user_transation_status: Yup.string().required( 
    "Transaction Status is required"
  ),
  payee_vpa: Yup.string().email().required("Payee VPA is required"),
  payer_vpa: Yup.string().email().required("Payer VPA is required"),
  refund_status: Yup.string().required("Refund Status is required"),
  // transaction_failed_reason: Yup.string().required(
  //   "Transaction Failed Reason is required"
  // ),
  transaction_failed_reason: Yup.string().when(
    "user_transation_status",
    ([company], schema) => {
      if (company === "pending" || company === "failed")
        return Yup.string().required(
          "Transaction Failed / Pending Reason is required"
        );
      return schema;
    }
  ),

  // refund_reason: Yup.string().required("Refund Reason is required"),
  receiver_bank_name: Yup.string().required("Reciever Name is required"),
  bank_ref_no: Yup.string().required("Bank Reference Number is required"),
  
  receiver_bank_account_no: Yup.string()
  .required("Receiver Bank Account No. is required")
  .matches(/^[0-9]+$/, "Receiver Bank Account No. must contain only numbers"),
  // receiver_bank_account_no:
  //  Yup.string().required(
  //   "Reciever Bank acc no. is required"
  // ),
  receiver_bank_IFSC: Yup.string().required(
    "Reciever Bank IFSC field is required"
  ),
  // transaction_remark: Yup.string().required("Transaction Remark is required"),
  attachment: Yup.string().required("Attachment is required"),
  // transction_amount: Yup.string().required(
  //   "Transaction Amount field is required"
  // ),
});

export const LoginValidations = Yup.object().shape({
  email: Yup.string().email().required("Email field is required"),
  password: Yup.string().required("Password field is required"),
});

export const ForgotPasswordValidations = Yup.object().shape({
  email: Yup.string().required("Email field is required"),
});
export const VerifyOtpValidations = Yup.object().shape({
  otp: Yup.string().required("Email field is required"),
});
export const AddNewPasswordValidations = Yup.object().shape({
  newPassword: Yup.string().required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const ChangeNewPasswordValidations = Yup.object().shape({
  // new_password: Yup.string().required("New Password is required"),
  // confirm_password: Yup.string().required("Confirm Password field is required"),

  newPassword: Yup.string().required("New Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const AddBankValidations = Yup.object().shape({
  // bank_name: Yup.string().required("Bank field is required"),
  type: Yup.string().required("Type of Branch field is required"),
  // ifsc_code: Yup.string().required("Branch Code field is required"),
  ifsc_code: Yup.string()
    .required("IFSC Code is required")
    .min(11, "IFSC Code must be 11 characters long")
    .max(11, "IFSC Code must be 11 characters long")
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format"),
  email: Yup.string().email().required("Email field is required"),
  // email: Yup.string().email().required("Email field is required"),
  //  email: Yup.string()
  //   .email('Invalid email address')
  //   .matches(/^[\w.%+-]+@sbi\.com$/, 'Email must be from @sbi.com domain')
  //   .required('Email is required'),
  manager_name: Yup.string().required("Manager Name field is required"),
  location: Yup.string().required("Location field is required"),
  branch_location: Yup.string().required("Branch Location field is required"),
  contact_number: Yup.string()
    .required("Contact field is required")
    .matches(
      /^\d{10}$/,
      "Contact number must be exactly 10 digits and contain only numbers"
    ),
});

export const AddReciverBankDetailsValidations = Yup.object().shape({
  transaction_status: Yup.string().required("Transaction status is required"),
  refund_status: Yup.string().required("Refund Status field is required"),
  transaction_failed_reason: Yup.string().when(
    "transaction_status",
    ([company], schema) => {
      if (company === "pending" || company === "failed")
        return Yup.string().required(
          "Transaction Failed / Pending Reason is required"
        );
      return schema;
    }
  ),
  // refund_reason: Yup.string().required("Refund Reason is required"),
  refund_utr_no: Yup.string()
  .required("Refund UTR no. is required")
  .length(12, "UTR no. must be exactly 12 digits")
  .matches(/^\d{12}$/, "UTR no. must contain only digits"),
  refund_date_time: Yup.string().required("Refund Date & Time is required"),
  transaction_remark: Yup.string().required("Transaction Remark is required"),
  // payment_gateway: Yup.string().required("Payment Gateway is required"),
  switch_bank: Yup.string().required("Switch Bank is required"),
});
export const AddSwitchBankCompValidations = Yup.object().shape({
  transaction_status: Yup.string().required("Transaction status is required"),
  refund_status: Yup.string().required("Refund Status field is required"),
  transaction_failed_reason: Yup.string().when(
    "transaction_status",
    ([company], schema) => {
      if (company === "pending" || company === "failed")
        return Yup.string().required(
          "Transaction Failed / Pending Reason is required"
        );
      return schema;
    }
  ),
  refund_reason: Yup.string().required("Refund Reason is required"),
  transaction_remark: Yup.string().required("Transaction Remark is required"),
  payment_gateway: Yup.string().required("Payment Gateway is required"),
});
export const PaymentGatewayUpdateValidations = Yup.object().shape({
  transaction_status: Yup.string().required("Transaction status is required"),
  refund_status: Yup.string().required("Refund Status is required"),
  transaction_failed_reason: Yup.string().when(
    "transaction_status",
    ([company], schema) => {
      if (company === "pending" || company === "failed")
        return Yup.string().required(
          "Transaction Failed / Pending Reason is required"
        );
      return schema;
    }
  ),
  // refund_reason: Yup.string().required("Refund Reason is required"),
  merchant: Yup.string().required("Merchant is required"),
  udf_no: Yup.string().required("UDF No. is required"),
  proof_attachment: Yup.string().required("Payment Attachment is required"),
  transaction_remark: Yup.string().required("Transaction Remark is required"),
});

export const AddMerchantValValidations = Yup.object().shape({
  transaction_status: Yup.string().required("Transaction status is required"),
  refund_status: Yup.string().required("Refund Status field is required"),
  // transaction_failed_reason: Yup.string().required(
  //   "Transaction Failed Reason is required"
  // ),
  refund_reason: Yup.string().required("Refund Reason is required"),
  transaction_remark: Yup.string().required("Transaction Remark is required"),
  // switch_bank: Yup.string().required("Switch Bank is required"),
});

export const AddCyberCellValidations = Yup.object().shape({
  cell_name: Yup.string().required("Cyber Cell Name is required"),
  cell_branch: Yup.string().required("Cyber Cell Branch is required"),
  cell_location: Yup.string().required("Cyber Cell Location is required"),
  cell_code: Yup.string().required("Cyber Cell Code is required"),
  email: Yup.string().required("Cyber Cell Email  is required"),
  type: Yup.string().required("Type is required"),
});
export const AddPaymentGatewayValidations = Yup.object().shape({
  gateway_name: Yup.string().required("Payment Gateway Name field is required"),
  gateway_location: Yup.string().required(
    "Payment Gateway Location field is required"
  ),
  email: Yup.string().required("Payment Gateway Email field is required"),
  gateway_contact: Yup.string()
    .required("Payment Gateway Contact field is required")
    .matches(
      /^\d{10}$/,
      "Contact number must be exactly 10 digits and contain only numbers"
    ),
  gateway_auth_person: Yup.string().required(
    "Payment Gateway Auth Person field is required"
  ),
  gateway_licence_no: Yup.string().required(
    " Payment Gateway Licence No. field is required"
  ),
});

export const AddMerchantValidations = Yup.object().shape({
  merchant_name: Yup.string().required("Merchant Name field is required"),
  mid: Yup.string().required("MID field is required"),
  marchant_vpa: Yup.string().required("merchantVPA field is required"),
  marchant_address: Yup.string().required("merchantAddress field is required"),
  city: Yup.string().required("City field is required"),
  state: Yup.string().required("State field is required"),
  pincode: Yup.string().required("Pincode field is required"),
  country: Yup.string().required("Country field is required"),
  url_website: Yup.string().required("Website Url field is required"),
  app_url: Yup.string().required("appURL field is required"),
  gateway_name: Yup.string().required("Payment Gateway Name field is required"),
});
