import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CyberTable from "../../components/Tables/CyberTable";
import MerchantTable from "../../components/MerchantTable";
import { GetSelfCreatedOrganization } from "../../controller/organization";
import BankTable from "../../components/Tables/BankTable";
import PaymentGatewayTable from "../../components/Tables/PaymentGatewayTable";
import DashboardHeader from "../../components/DashboardHeader";

function AdminUserManagement() {
  const authToken = JSON.parse(localStorage.getItem("authToken"));
 const navigate= useNavigate()
  const tab = 
   authToken?.user?.role == "CYBERCELL"
  ? 1
  : authToken?.user?.role == "PAYMENT"
  ? 2
  : authToken?.user?.role == "MERCHANT"
  ? 3
  : 0;
  const [tabs, setTabs] = useState(tab);

  
  const [selfOrganization, setSelfOrganization] = useState();
  if (authToken) {
    try {
      const authData = authToken;
      // console.log("auth",authData)
      var token = authData.token;   
      var userId = authData.user_id;
    } catch (e) {
      console.error("Error parsing authToken:", e);
    }
  } else {
    console.error("authToken not found in localStorage");
  }
  useEffect(() => {
    GetSelfCreatedOrganization(token)
      .then((data) => setSelfOrganization(data.data))
      .catch((err) => console.log(err));
  }, []);

  const TableHeading = [
    "S.No.",
    "Bank Name",
    "Type of Branch",
    "Branch Code / IFSC",
    "Email Id",
    "Manager Name",
    "Location",
    "Branch Location",
    "Status",
    "Contact No.",
  ];
  const CyberTableHeading = [
    "S.No.",
    "Cyber Cell Name",
    "Cyber Cell Branch",
    "Cyber Cell Location",
    "Cyber Cell Code",
    "Email",
    "Status",
    "Type",
  ];
  const PaymentTableHeading = [
    "S.No.",
    "Payment Gateway Name",
    "Location",
    "Contact No",
    "Email",
    "Payment Gateway Auth Person",
    "Status",
    "Licence No",
  ];
  const MerchantTableHeading = [
    "S.No.",
    "Merchant Name",
    "MID",
    "Merchant VPZ",
    "Merchant Address",
    "City",
    "State",
    "Pincode",
    "Country",
    "Status",
    "URL Website",
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
      {/* Dashboard header start */}
      
      <DashboardHeader title={"Pages / Dashboard"} subtitle={"User Management"} />
      {/* Dashboard header end */}

      {/* pagination start */}

      <div className="flex justify-between items-center px-2 pt-5">
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-4 items-center">
            {authToken.user.role == "BANK" && (
              <div
                onClick={() => {
                  setTabs(0);
                }}
                className={
                  tabs == 0
                    ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                    : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
                }
              >
                <span
                  className={
                    tabs == 0
                      ? "text-sm font-poppins text-[#3F85EC] font-medium"
                      : "text-sm font-poppins text-[#85868A]"
                  }
                >
                  Bank
                </span>
              </div>
            )}

            {console.log("role==>>",authToken.user.role)}

            {authToken.user.role == "CYBERCELL" && (
              <div
                onClick={() => {
                  setTabs(1);
                }}
                className={
                  tabs == 1
                    ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                    : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
                }
              >
                <span
                  className={
                    tabs == 1
                      ? "text-sm font-poppins text-[#3F85EC] font-medium"
                      : "text-sm font-poppins text-[#85868A]"
                  }
                >
                  Cyber Cell / Police
                </span>
              </div>
            )}

            {authToken.user.role == "PAYMENT" && (
              <div
                onClick={() => {
                  setTabs(2);
                }}
                className={
                  tabs == 2
                    ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                    : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
                }
              >
                <span
                  className={
                    tabs == 2
                      ? "text-sm font-poppins text-[#3F85EC] font-medium"
                      : "text-sm font-poppins text-[#85868A]"
                  }
                >
                  Payment Gateway
                </span>
              </div>
            )}

            {authToken.user.role == "MERCHANT" && (
              <div
                onClick={() => {
                  setTabs(3);
                }}
                className={
                  tabs == 3
                    ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                    : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
                }
              >
                <span
                  className={
                    tabs == 3
                      ? "text-sm font-poppins text-[#3F85EC] font-medium"
                      : "text-sm font-poppins text-[#85868A]"
                  }
                >
                  Merchant
                </span>
              </div>
            )}
          </div>

          {/* <Link to="/admin/add-new-user"> */}

          {authToken?.user?.role !== "MERCHANT" && (
          <div   onClick={() => {
              navigate("/admin/add-new-user", {
                state: {
                  tab: tabs,
                },
              });
            }}
        >
            <button className="bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC] px-4 py-2 rounded-full text-[#fff]">
              Add Organization
            </button>
          </div>
          )}
        </div>
      </div>
      {/* pagination end */}
      <div className="px-4">
        <div className="bg-[#fff]">
          {tabs == 0 && (
            <BankTable
              TableHeading={TableHeading}
              sampleData={selfOrganization}
            />
          )}
          {tabs == 1 && (
            <CyberTable
              TableHeading={CyberTableHeading}
              sampleData={selfOrganization}
            />
          )}
          {tabs == 2 && (
            <PaymentGatewayTable
              TableHeading={PaymentTableHeading}
              sampleData={selfOrganization}
            />
          )}
          {tabs == 3 && (
            <MerchantTable
              TableHeading={MerchantTableHeading}
              sampleData={selfOrganization}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminUserManagement;
