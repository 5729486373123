import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AddReciverBankDetailsValidations } from "../data/validations";
import { GetAllBanks } from "../controller/organization";
import ReactCustomSelect from "./ui/ReactCustomSelect";
import { AddRecieverAdminComplaintsApi } from "../controller/complaints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { base } from "../config";

const RecieverBankComp = ({
  recieverBankDetails,
  viewComplaint,
  id,
  formState,
  handleSubmit,
}) => {
  // console.log("formState===", formState);
  // console.log("viewComplaint", viewComplaint);
  const [allBanks, setAllBanks] = useState();
  const authToken = localStorage.getItem("authToken");
  const token = JSON.parse(authToken);
  const navigate = useNavigate();

  const customStyles = (hasError) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: hasError ? "red" : provided.borderColor,
      boxShadow: state.isFocused ? 0 : 0,
    }),
  });

  // useEffect(() => {
  //   setFormState({...setFormState, reciever_Bank:viewComplaint.receiver_bank_status?})
  // }, [])

  useEffect(() => {
    GetAllBanks(token.token)
      .then((res) => {
        setAllBanks(res.bank);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const bank = allBanks?.find(
    (item) => item?.bankname === recieverBankDetails?.switch_bank
  );

  console.log("bankingggg----", bank);

  const handleADD = (val) => {
    console.log("value---of reciever bank---", val);

    let data = new FormData();

    data.append("complaint_number", val?.complaint_number);
    data.append("refund_date_time", val?.refund_date_time);
    data.append("refund_reason", val?.refund_reason);
    data.append("refund_status", val?.refund_status);
    data.append(
      "refund_transaction_screenshot",
      val?.refund_transaction_screenshot
    );
    data.append("refund_utr_no", val?.refund_utr_no);
    data.append("switch_bank", val?.switch_bank);
    data.append("transaction_failed_reason", val?.transaction_failed_reason);
    data.append("transaction_remark", val?.transaction_remark);
    data.append("transaction_status", val?.transaction_status);

    AddRecieverAdminComplaintsApi(token.token, id, data)
      .then((res) => {
        // console.log("first----", res)
        toast.success(res.message);
        // // resetForm({});
        navigate("/admin/complaint-management");
      })
      .catch((error) => {
        console.log(error);
      });
    handleSubmit(data);
  };
  return (
    <div>
      <Formik
        initialValues={{
          transaction_status: viewComplaint
            ? viewComplaint?.receiver_bank_status?.transaction_status
            : "",
          refund_status: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_status
            : "",
          refund_utr_no: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_utr_no
            : "",
          transaction_failed_reason: viewComplaint
            ? viewComplaint?.receiver_bank_status?.transaction_failed_reason
            : "",
          refund_reason: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_reason
            : "",
          transaction_remark: viewComplaint
            ? viewComplaint?.receiver_bank_status?.transaction_remark
            : "",
          switch_bank: viewComplaint
            ? viewComplaint?.receiver_bank_status?.switch_bank
            : "",
          complaint_number: viewComplaint
            ? viewComplaint?.complaint_Details?.complaint_number
            : "",
          refund_utr_no: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_utr_no
            : "",
          refund_date_time: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_date_time
            : "",
          refund_transaction_screenshot: viewComplaint
            ? viewComplaint?.receiver_bank_status?.refund_transaction_screenshot
            : "",
        }}
        onSubmit={handleADD}
        validationSchema={AddReciverBankDetailsValidations}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <div className=" rounded-xl p-4">
            <div className="pt-2">
              <div>
                <h3 className="text-primary font-poppins font-medium text-xl">
                  Reciever Bank Status
                </h3>
              </div>
            </div>

            <div className="flex flex-wrap ">
              <div className="w-full lg:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Status
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>

                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("transaction_status", e.target.value)
                      }
                      defaultValue={
                        viewComplaint?.receiver_bank_status?.transaction_status
                      }
                      disabled={
                        viewComplaint?.receiver_bank_status?.transaction_status
                          ? true
                          : false
                      }
                      className={`${
                        errors.transaction_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    >
                      <option className="text-lg">
                        --Select Transaction Status --
                      </option>
                      <option value={"pending"} className="text-lg">
                        Pending
                      </option>
                      <option value={"success"} className="text-lg">
                        Success
                      </option>
                      <option value={"failed"} className="text-lg">
                        Failed
                      </option>
                    </select>
                    {errors.transaction_status && touched.transaction_status ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_status}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-1/2">
                <div className="p-2">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224]">
                      Refund Status
                    </h3>
                    <div>
                      <span className="font-bold text-[#ef4444] text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="pt-3">
                    <select
                      onChange={(e) =>
                        setFieldValue("refund_status", e.target.value)
                      }
                      defaultValue={
                        viewComplaint?.receiver_bank_status?.refund_status
                      }
                      disabled={
                        viewComplaint?.receiver_bank_status?.refund_status
                          ? true
                          : false
                      }
                      className={`${
                        errors.refund_status
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                    >
                      <option className="text-lg">
                        --Select Refund Status --
                      </option>
                      <option value={"yes"} className="text-lg">
                        Yes
                      </option>
                      <option value={"no"} className="text-lg">
                        No
                      </option>
                    </select>
                    {errors.refund_status && touched.refund_status ? (
                      <p className="text-[#ef4444]">{errors.refund_status}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              {values?.transaction_status != "success" && (
                <div className="w-full md:w-1/2">
                  <div className="m-3">
                    <div className="flex gap-1">
                      <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                        Transaction Failed Reason.
                      </h3>
                      <div>
                        <span className=" font-bold text-[#ef4444]  text-red-500">
                          *
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <input
                        placeholder="Transaction Failed Reason."
                        onChange={handleChange("transaction_failed_reason")}
                        onBlur={handleBlur("transaction_failed_reason")}
                        value={values.transaction_failed_reason}
                        className={`${
                          errors.transaction_failed_reason
                            ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                            : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        }`}
                        disabled={
                          viewComplaint?.receiver_bank_status
                            ?.transaction_failed_reason
                            ? true
                            : false
                        }
                      />
                      {errors?.transaction_failed_reason &&
                      touched?.transaction_failed_reason ? (
                        <p className="text-[#ef4444]">
                          {errors?.transaction_failed_reason}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Reason.
                    </h3>
                    {/* <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Refund Reason."
                      onChange={handleChange("refund_reason")}
                      onBlur={handleBlur("refund_reason")}
                      value={values.refund_reason}
                      className={`${
                        errors.refund_reason
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        viewComplaint?.receiver_bank_status?.refund_reason
                          ? true
                          : false
                      }
                    />
                    {errors.refund_reason && touched.refund_reason ? (
                      <p className="text-[#ef4444]">{errors.refund_reason}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* new fields starts */}

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund UTR No.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Refund UTR No."
                      onChange={handleChange("refund_utr_no")}
                      onBlur={handleBlur("refund_utr_no")}
                      value={values.refund_utr_no}
                      className={`${
                        errors.refund_utr_no
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        viewComplaint?.receiver_bank_status?.refund_utr_no
                          ? true
                          : false
                      }
                    />
                    {errors?.refund_utr_no && touched?.refund_utr_no ? (
                      <p className="text-[#ef4444]">{errors?.refund_utr_no}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Refund Date & Time.
                    </h3>
                    {/* <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Refund Date & Time"
                      type="datetime-local"
                      onChange={handleChange("refund_date_time")}
                      onBlur={handleBlur("refund_date_time")}
                      value={values.refund_date_time}
                      className={`${
                        errors.refund_date_time
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        viewComplaint?.receiver_bank_status?.refund_date_time
                          ? true
                          : false
                      }
                    />
                    {errors.refund_date_time && touched.refund_date_time ? (
                      <p className="text-[#ef4444]">
                        {errors.refund_date_time}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* new fields ends*/}

            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction Remark.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <input
                      placeholder="Transaction Remark."
                      onChange={handleChange("transaction_remark")}
                      onBlur={handleBlur("transaction_remark")}
                      value={values.transaction_remark}
                      className={`${
                        errors.transaction_remark
                          ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                          : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                      }`}
                      disabled={
                        viewComplaint?.receiver_bank_status?.transaction_remark
                          ? true
                          : false
                      }
                    />
                    {errors.transaction_remark && touched.transaction_remark ? (
                      <p className="text-[#ef4444]">
                        {errors.transaction_remark}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Switch Bank.
                    </h3>
                    <div>
                      <span className=" font-bold text-[#ef4444]  text-red-500">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <ReactCustomSelect
                      styles={customStyles(errors?.section?.message)}
                      options={allBanks}
                      defaultValue={bank}
                      multiSelect={true}
                      onchange={(item) =>
                        setFieldValue("switch_bank", item.bankname)
                      }
                    />

                    {errors.switch_bank && touched.switch_bank ? (
                      <p className="text-[#ef4444]">{errors.switch_bank}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <div className="m-3">
                  <div className="flex gap-1">
                    <h3 className="font-nunito font-semibold text-md text-[#202224] ">
                      Transaction ScreenShot
                    </h3>
                    {/* <div>
                       <span className=" font-bold text-[#ef4444]  text-red-500">
                         *
                       </span>
                     </div> */}
                  </div>

                  {!viewComplaint?.receiver_bank_status
                    ?.refund_transaction_screenshot ? (
                    <div className="mt-3">
                      <input
                        placeholder="Refund Transaction Screenshot."
                        type="file"
                        onChange={(event) => {
                          const file = event?.target?.files[0];
                          if (file) {
                            console.log("Selected file:", file);
                            setFieldValue(
                              "refund_transaction_screenshot",
                              file
                            );
                          }
                        }}
                        onBlur={handleBlur("refund_transaction_screenshot")}
                        //  value={values.refund_transaction_screenshot}
                        className={`${
                          errors.refund_transaction_screenshot
                            ? "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#ef4444]"
                            : "w-full placeholder:bg-[#fff] h-9 border focus:outline-none px-2 rounded-sm border-[#dfdfdf]"
                        }`}
                        disabled={
                          viewComplaint?.receiver_bank_status
                            ?.refund_transaction_screenshot
                            ? true
                            : false
                        }
                      />
                      {errors.refund_transaction_screenshot &&
                      touched.refund_transaction_screenshot ? (
                        <p className="text-[#ef4444]">
                          {errors.refund_transaction_screenshot}
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <div className="w-32">
                      <img
                        src={
                          base.BASE_URL +
                          viewComplaint?.receiver_bank_status?.refund_transaction_screenshot.split(
                            "public/"
                          )[1]
                        }
                        className=" w-full object-cover"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {formState?.reciever_Bank && formState?.switch_Bank ? (
              ""
            ) : (
              <div className="flex items-center  justify-end gap-4 mt-8">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-gradient-to-r from-[#3C76D5] px-12 to-[#0AB9FC] py-2 rounded-full text-[#fff]"
                >
                  Submit
                </button>
              </div>
            )}

            {/* new field */}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RecieverBankComp;
