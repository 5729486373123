import React, { useEffect, useState } from "react";
import { GetAllSuperAdminComplaints } from "../../controller/complaints";
import AllSuperAdminComplaints from "../../components/Tables/AllSuperAdminComplaints";
import DashboardHeader from "../../components/DashboardHeader";

function Complaint() {

  const[sampleData,setSampleData] = useState()
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
      try {
          const authData = JSON.parse(authToken);
          var token = authData.token;
          var userId = authData.user_id;

      
      } catch (e) {
          console.error("Error parsing authToken:", e);
      }
  } else {
      console.error("authToken not found in localStorage");
  }

  useEffect(() => {
    GetAllSuperAdminComplaints(token)
      .then((data) => setSampleData(data?.data))
      .catch((err) => console.log(err));
  }, []);

  const TableHeading = [
    "S.No.",
    "Complaint Number",
    "Complaint Date",
    "Complaint Generated By",
    "Complaint Category",
    "Complaint Sub Category",
    "Consumer Name",
    "Action",
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
    
      <DashboardHeader />
      {/* Dashboard header end */}

      {/* pagination start */}

      <div className="flex justify-between items-center px-2">
        <div className="flex gap-4 items-center">
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Verify Complaint
            </span>
          </div>
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Reject Complaint
            </span>
          </div>
        </div>

         
      </div>

      {/* pagination end */}
      <div>
        <div className="px-3">
          <AllSuperAdminComplaints sampleData={sampleData} TableHeading={TableHeading} />
        </div>
      </div>
    </div>
  );
}

export default Complaint;
