import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiInfo } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";

import { Link } from "react-router-dom";
import { GetAllAdminComplaintsApi } from "../../controller/complaints";
import Table from "../../components/TableAgent";
import AllAdminComplaintsTable from "../../components/Tables/AllAdminComplaintsTable";
import DashboardHeader from "../../components/DashboardHeader";

function AdminComplaintManagement() {
  const [sampleData, setSampleData] = useState();
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
    try {
      const authData = JSON.parse(authToken);
      var token = authData.token;
      var userId = authData.user_id;
       
      // console.log("User ID:", userId);
    } catch (e) {
      console.error("Error parsing authToken:", e);
    }
  } else {
    console.error("authToken not found in localStorage");
  }
  
  useEffect(() => {
    GetAllAdminComplaintsApi(token)
      .then((data) => setSampleData(data.data))
      .catch((err) => console.log(err));
  }, []);

  const TableHeading = [
    "S.No.",
    "Complaint Number",
    "Complaint Date",
    "Complaint Generated By",
    "Complaint Category",
    "Complaint Sub Category",
    "Consumer Name",
    "Action"
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
      {/* Dashboard header start */}
    <DashboardHeader title={"Pages / Dashboard"} subtitle={"Complaint Management"}/>
      {/* Dashboard header end */}

      {/* pagination start */}

      <div className="flex justify-between items-center px-2 pt-5">
        <div className="flex gap-4 items-center">
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Verify Complaint
            </span>
          </div>
          <div className="py-1 px-2 bg-[#fff] border border-[#85868A] rounded-md cursor-pointer">
            <span className="text-sm font-poppins text-[#85868A]">
              Reject Complaint
            </span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Link to="/admin/add-new-complaint">
            <button className="bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC] px-8 py-2 rounded-full text-[#fff]">
              File Complaint
            </button>
          </Link>
        </div>
      </div>

      {/* pagination end */}
      <div>
        <div>
          <AllAdminComplaintsTable
            sampleData={sampleData}
            TableHeading={TableHeading}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminComplaintManagement;
